import { gql }  from '@apollo/client';

const getReport = gql`
  query getReport($reportType: String, $relationshipId: ID!, $locationIds: [Int], $startDate: String, $endDate: String) {
    getReport(reportType: $reportType, relationshipId: $relationshipId, locationIds: $locationIds, startDate: $startDate, endDate: $endDate) {
      rows
      totals
    } 
  }
`;

const getSummaryReport = gql`
  query getSummaryReport($relationshipId: ID!, $locationIds: [Int], $startDate: String, $endDate: String) {
    getSummaryReport(relationshipId: $relationshipId, locationIds: $locationIds, startDate: $startDate, endDate: $endDate) {
      rows
      totals
    } 
  }
`;

const getRelationship = gql`
  query Relationship($id: ID!) {
    getRelationship(id: $id) {
      id
      name
      rolloverMappings
      partnerSharePercent
      safeleaseStatus
      billingAgreementFirstName
      billingAgreementLastName
      csm {
        name
        calendlyLink
      }
      am {
        name
        calendlyLink
      }
      isInsuranceSetupComplete
      isInsuranceEnabled
      isInsightsEnabled
      isSafeleaseLiteEnabled
      featureFlags
      hasLocations
      amUserId
      csmUserId
      test
    }
  }
`;

const getRelationships = gql`
  query getRelationships($search: String) {
    getRelationships(search: $search) {
      id
      name
      statusText
      createdAt
      earliestLiveDate
      totalLocations
      allFms
      totalActiveJobs
      totalFailedJobs
      safeleaseStatus
      earliestLiveDate
      salesforceSync
    }
  }
`;

const getRelationshipsSelector = gql`
  query getRelationshipsSelector {
    getRelationshipsSelector {
      id
      name
    }
  }
`

const getUsers = gql`
  query UsersForRelationship($relationshipId: ID!, $offset: Int, $limit: Int, $order: String) {
    getUsers(relationshipId: $relationshipId, offset: $offset, limit: $limit, order: $order) {
      edges {
        id
        name
        email
        phone
        role
        canDelete
        locationIds
        permissions
        isAdmin
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

const getUserById = gql`
  query getUserById($id: ID!) {
    getUserById(id: $id) {
      id
      name
      email
    }
  }
`

const getUserByToken = gql`
  query getUserByToken($token: String) {
    getUserByToken(token: $token) {
      id
      name
      email
      relationshipName
    }
  }
`;

const me = gql`
  query me($token: String) {
    me(token: $token) {
      id
      name
      email
      phone
      grantableRoles
      termsAgreedAt
      otpEnrollmentPossible
      otpEnrolled
      lastInformedOfNotificationsAt
      relationshipId
      relationshipName
      role
      permissions
      reportKeyStats
      hasPassword
      emailVerifiedAt
      features
    }
  }
`;

const listLocations = gql`
  query listLocations($relationshipId: ID, $llcId: ID) {
    listLocations(relationshipId: $relationshipId, llcId: $llcId) {
      accountId
      billingStatus
      address
      attachRate
      autoEnrollExistingEffectiveDate
      autoEnrollEligibleRevenueFormatted
      autoEnrollEligibleUnits
      autoEnrollInitialCount
      autoEnrollInitialRevenue
      customerRevenue
      deauthenticatedAt
      delinquentUnits
      eligible
      emailSentAt
      activeJobs
      failedJobs
      fms
      id
      llcId
      moveOuts
      occupiedUnits
      relationshipId
      relationshipName
      revenueSharePercentage
      rolloverDate
      safeleaseRevenueFormatted
      safeleaseUnits
      sendEmailDate
      totalMonthlyRevenue
      safeleaseStatus
    }
  }
`;

const getLocationsByLlc = gql`
  query getLocationsByLlc($llcId: ID!) {
    getLocationsByLlc(llcId: $llcId) {
      id
      relationshipId
      fullAddress
      strategy
      rolloverDate
      autoEnrollStartDate
      accountId
    }
  }
`

const getLocations = gql`
  query getLocations($relationshipId: ID!) {
    getLocations(relationshipId: $relationshipId) {
      id
      relationshipId
      name
      address
      city
      state
      postalCode
      accountId
      accountType
      serviceId
      rolloverDate
      sendEmailDate
      fullAddress
      llcId
      autoEnrollStartDate
      legacyUnitExclusion
      legacyUnitExclusionStartDate
      autoEnrollReminderDaysBefore
      autoEnrollGracePeriod
      legacy
      legacyNotes
      processRollover
      liveDate
      strategy
      unsupported
      hasCompleteAutoEnroll
      customerRevenueThisMonth
      totalRevenueThisMonth
      lastIngestedAt
      legacyUnitAutoEnrollStartDate
      autoEnrollExistingEnabled
      autoEnrollExistingEffectiveDate
      autoEnrollNewEnabled
      autoEnrollNewEffectiveDate
      setupVerified
      setupDate
      setupCompletedAt
      recentData
      billingEntityId
      baselineProtectedUnits
      baselineOccupiedUnits
      baselineMonthlyRevenue
      revenueSharePercent
      setupStatus
      safeleaseLiteEnabled
      insightsEnabled
      insuranceEnabled
      lat
      lng
      customProtectionLevels
      protectionLevelValues
      automaticBilling
      firstChargeNextBillingCycle
      manualBillingType
      landingWebsiteUrl
      privacyPolicyUrl
      textingOptInInstructions
    }
  }
`;

const getLocation = gql`
  query getLocation($locationId: ID!) {
    getLocation(locationId: $locationId) {
      id
      name
      address
      city
      state
      postalCode
      accountId
      serviceId
      rolloverDate
      sendEmailDate
      fullAddress
      llcId
      autoEnrollStartDate
      legacyUnitExclusion
      legacyUnitExclusionStartDate
      autoEnrollReminderDaysBefore
      autoEnrollGracePeriod
      legacy
      legacyNotes
      processRollover
      liveDate
      strategy
      unsupported
      legacyUnitAutoEnrollStartDate
      autoEnrollExistingEnabled
      autoEnrollExistingEffectiveDate
      autoEnrollNewEnabled
      autoEnrollNewEffectiveDate
      setupVerified
      setupDate
      setupCompletedAt
      automaticBilling
      customProtectionLevels
      protectionLevelValues
      firstChargeNextBillingCycle
      landingWebsiteUrl
      privacyPolicyUrl
      textingOptInInstructions
    }
  }
`;

const getLocationAddresses = gql`
  query getLocationAddresses($locationId: ID!) {
    getLocationAddresses(locationId: $locationId) {
      id
      locationId
      primary
      address
      city
      state
    }
  }
`

const getClaims = gql `
  query getClaims($relationshipId: ID, $locationId: ID) {
    getClaims(relationshipId: $relationshipId , locationId: $locationId) {
      id
      causes
      name
      email
      discoveryDate
      filedToPolice
      dateFiled
      description
      claimSubmissionDate
      matchAccuracy
      matchedLocation
      externalLocation
      externalId
      locationId
      locationConfirmed
      unit
      lossDate
      lossPhoto
      claimAmount
      coverageAmount
      coveredStatus
      status
      whoDiscovered
      fileRecipient
      basisForClaimDenial
      isDemo
      needsFromTenant
    }
  }
`;

const getClaimNotes = gql`
  query getClaimNotes($locationId: ID!, $claimId: ID!) {
    getClaimNotes(locationId: $locationId, claimId: $claimId) {
      notes {
        id
        locationId
        claimId
        senderUserId
        type
        text
        createdAt
        senderName
        senderEmail
      }
      activeUserInfo {
        userId 
        userEmail
        userName
      }
    }
  }
`

const getAllNotifications = gql`
  query getAllNotifications {
    getAllNotifications {
      id
      userId
      noteId
      notificationType
      claimId
      claimExternalId
      checkedAt
      createdAt
    }
  }
`

const getClaimsMapMetrics = gql`
  query getClaimsMapMetrics($causes: [String]) {
    getClaimsMapMetrics(causes: $causes) {
      id
      lng
      lat
      totalPaid
      countPaid
      totalRisk
      countRisk
      name
      address
      city
      state
      postalCode
      relationshipId
      relationshipName
      llcId
      llcName
    }
  }
`

const getRelationshipsByClaims = gql`
  query getRelationshipsByClaims($search: String, $causes: [String]) {
    getRelationshipsByClaims(search: $search, causes: $causes) {
      id
      name
      countRisk
      countPaid
      totalRisk
      totalPaid
    }
  }
`;

const getLlcsByClaims = gql`
  query getLlcsByClaims($relationshipId: ID!, $causes: [String]) {
    getLlcsByClaims(relationshipId: $relationshipId, causes: $causes) {
      id
      name
      countRisk
      countPaid
      totalRisk
      totalPaid
    }
  }
`;

const getAllProspects = gql`
  query getAllProspects($page: Int) {
    getAllProspects(page: $page) {
      id
      website
      fms
      name
      address
      city
      state
      lat
      lng
      fullAddress
      salesforceUrl
    }
  }
`;

const getAllLocations = gql`
  query getAllLocations {
    getAllLocations {
      id
      address
      city
      state
      postalCode
      qualifiedName
      accountType
      lat
      lng
      fullAddress
    }
  }
`;

const getAccount = gql`
  query getAccount($accountId: ID!) {
    getAccount(accountId: $accountId) {
      id
      relationshipId
      type
      credentials
      locations
      integrationName
      setupStatus
    }
  }
`;

const getAccounts = gql`
  query getAccounts($relationshipId: ID!) {
    getAccounts(relationshipId: $relationshipId) {
      id
      relationshipId
      type
      credentials
      locations
      integrationName
      setupStatus
    }
  }
`;

const getJobs = gql`
  query getJobs($relationshipId: ID!) {
    getJobs(relationshipId: $relationshipId) {
      id
      methodDescription
      statusDescription
      runAt
      lastCompletedAt
      locationId
      hasLogs
      method
      error
      failureCount
    }
  }
`;

const getJob = gql`
  query getJob($jobId: ID!) {
    getJob(jobId: $jobId) {
      id
      method
      status
      methodDescription
      statusDescription
      error
      failureCount
    }
  }
`;

const getJobsAdmin = gql`
  query getJobsAdmin {
    getJobsAdmin {
      id
      locationId
      address
      runAt
      method
      lastCompletedAt
      error
      status
      failedAt
      failureCount
    }
  }
`

const getUsersAdmin = gql`
  query getUsersAdmin {
    getUsersAdmin {
      id
      role
      llcsSignedFor
      email
      locationIds
      relationshipName
      name
    }
  }
`

const getDocuments = gql`
  query getDocuments($relationshipId: ID!) {
    getDocuments(relationshipId: $relationshipId) {
      id
      createdAt
      fileType
      path
      relationshipId
      relationshipName
      llcId
      serviceDate
      invoiceStatus
      llcName
      subCategory
      locationAddresses
      locationIds
    }
  }
`

const getSavedPaymentMethods = gql`
  query getSavedPaymentMethods($relationshipId: ID!) {
    getSavedPaymentMethods(relationshipId: $relationshipId) {
      bankName
      obscuredAchAccountNumber
      obscuredAchRoutingNumber
      nameOnBankAccount
      accountType
      llcId
    }
  }
`

const getLlcs = gql`
  query getLlcs($relationshipId: ID!) {
    getLlcs(relationshipId: $relationshipId) {
      id
      name
      address
      fullAddress
      city
      state
      postalCode
      obscuredAchRoutingNumber
      obscuredAchAccountNumber
      email
      bankName
      nameOnBankAccount
      accountType
      touchedAt
      signature
      signatureUserId
      billingStatus
      invoiceDay
      dueDay
      safeleaseStatus
      relationshipId
      billPerLocation
      ein
      businessType
    }
  }
`;


const getLlc = gql`
  query getLlc($llcId: ID!) {
    getLlc(llcId: $llcId) {
      id
      name
      address
      city
      state
      postalCode
      achRoutingNumber
      achAccountNumber
      email
      bankName
      nameOnBankAccount
      accountType
      touchedAt
      signature
      signatureUserId
      ein
      businessType
    }
  }
`;

const getAllLlcs = gql`
  query getAllLlcs {
    getAllLlcs {
      id
      name
    }
  }
`;

const getAggregateMetrics = gql`
  query getAggregateMetrics($relationshipId: ID!, $locationId: ID, $insights: Boolean) {
    getAggregateMetrics(relationshipId: $relationshipId, locationId: $locationId, insights: $insights) {
      locationId
      date
      key
      value
    }
  }
`;

const getEmails = gql`
  query getEmails($locationId: ID) {
    getEmails(locationId: $locationId){
      locationId
      name
      body
      type
      subject
    }
  }
`;
  
const getUnits = gql`
  query getUnits($locationId: ID!) {
    getUnits(locationId: $locationId) {
      id
      locationId
      serviceId
      status
      name
      rate
      size
      type
      insurable
      autoEnrollStatus
      createdAt
      updatedAt
    }
  }
`;

const getReportDetails = gql`
  query getReportDetails($relationshipId: ID!, $locationId: ID, $startDate: String, $endDate: String) {
    getReportDetails(relationshipId: $relationshipId, locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      id
      address
      name
      moveInDate
      moveOutDate
      premium
      paidThroughDate
      startDate
      coverage
      cancelDate
      tenantName
      collectedSum
    }
  }
`;

const getReportDetailsTotals = gql`
  query getReportDetailsTotals($relationshipId: ID!, $locationId: ID, $startDate: String, $endDate: String) {
    getReportDetailsTotals(relationshipId: $relationshipId, locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      count
      coverage
      premium
      collected
    }
  }
`

const getNotificationSettings = gql`
  query getNotificationSettings {
    getNotificationSettings {
      emailSettings
    }
  }
`;

const getPossibleClaimMatches = gql`
  query getPossibleClaimMatches($externalLocation: String!, $numberOfMatches: Int, $claimId: ID!) {
    getPossibleClaimMatches(externalLocation: $externalLocation, numberOfMatches: $numberOfMatches, claimId: $claimId) {
      locationId
      matchAccuracy
      id
      matchedLocation
      locationConfirmed
    }
  }
`

const getPendingInvitations = gql`
  query getPendingInvitations {
    getPendingInvitations {
      id
      relationshipId
      relationshipName
      name
      email
      jobStatusDescription
    }
  }
`

const getAttachRateReports = gql`
  query getAttachRateReports {
    getAttachRateReports {
      id
      date
      attachmentId
    }
  }
`

const getPermissions = gql`
  query getPermissions {
    getPermissions {
      id
      userId
      relationshipId
      relationshipName
      role
      locationIds
    }
  }
`

const getAllEmails = gql`
  query getAllEmails {
    getAllEmails {
      id
      userId
      userName
      relationshipName
      type
      relationshipId
      nextSendAt
      lastSendAt
      emailName
      location
      rolloverDate
      autoEnrollDate
      disabled
      relationshipSendDisabled
    }
  }
`

const getInternalNotes = gql`
  query getInternalNotes($relationshipId: String, $locationId: String, $llcId: String) {
    getInternalNotes(relationshipId: $relationshipId, locationId: $locationId, llcId: $llcId) {
      id
      userId
      userName
      relationshipId
      locationId
      llcId
      content
      createdAt
      objectName
    }
  }
`

const getChanges = gql`
  query getChanges($relationshipId: String, $locationId: String, $llcId: String) {
    getChanges(relationshipId: $relationshipId, locationId: $locationId, llcId: $llcId) {
      id
      source
      key
      previousValue
      value
      createdAt
      content
      objectName
    }
  }
`

const getFundingSources = gql`
  query getFundingSources($relationshipId: ID!) {
    getFundingSources(relationshipId: $relationshipId) {
      id
      relationshipId
      name
      institution
      bankAccountType
      bankAccountMask
      setupStatus
      legacy
      fullAddress
      microdepositStatus
    }
  }
`;

const getBillingEntities = gql`
  query getBillingEntities($relationshipId: ID!) {
    getBillingEntities(relationshipId: $relationshipId) {
      id
      llcId
      fundingSourceId
      relationshipId
      name
      setupStatus
      billingMethod
    }
  }
`

const getInvoices = gql`
  query getInvoices($invoiceId: ID, $billingEntityId: ID, $servicePeriod: String, $adHocInvoices: Boolean) {
    getInvoices(invoiceId: $invoiceId, billingEntityId: $billingEntityId, servicePeriod: $servicePeriod, adHocInvoices: $adHocInvoices) {
      id
      attachmentId
      billingEntityId
      billingEntityName
      servicePeriod
      createdAt
      initiatedAt
      completedAt
      confirmed
      status
      dwollaTransferId
      emailSent
      balance
      amount
      relationshipName
      relationshipId
      autoConfirmFailureReason
    }
  }
`

const getInvoiceLineItems = gql`
  query getInvoiceLineItems($invoiceId: ID!) {
    getInvoiceLineItems(invoiceId: $invoiceId) {
      id
      invoiceId
      locationId
      type
      description
      amount
      locationFullAddress
      label
      recurringInvoiceLineItemId
      isDynamic
      autoConfirmed
      failureReason
    }
  }
`

const getRecurringInvoiceLineItems = gql`
  query getRecurringInvoiceLineItems($billingEntityId: ID!) {
    getRecurringInvoiceLineItems(billingEntityId: $billingEntityId) {
      id
      servicePeriodStartDate
      servicePeriodEndDate
      locationId
      type
      description
      isDynamic
      amount
      locationFullAddress
      label
    }
  }
`

const getJobsErrorReport = gql`
  query getJobsErrorReport{
    getJobsErrorReport{
      id
      relationshipName
      locationId
      locationAddress
      failedAt
      arguments
      method
      error
      errorNotes
      team
      csm
      csmNotes
      csmResolutionSteps
      triagingStatus
      assignee {
        id
        name
      }
    }
  }
`

const getLiveProductionJobData = gql `
  query getLiveProductionJobData {
    getLiveProductionJobData {
      pendingCount
      lastCompletedTimestamp
      latency
    }
  }
`

const getLiveProductionLatencyByFMS = gql`
  query getLiveProductionLatencyByFMS {
    getLiveProductionLatencyByFMS {
        latency
        type
    }
  }
`;

const getPermission = gql`
  query getPermission($objectType: String, $objectId: ID!, $permission: String){
    getPermission(objectType: $objectType, objectId: $objectId, permission: $permission){
      allowed
    }
  }
`;

/*************************
 * Tenant Portal
 */

const getTenantsLeases = gql`
  query getTenantsLeases{
    getTenantsLeases{
      id
      unitName
      locationAddress
    }
  }
`;

const getClaimsOverviewLists = gql`
query getClaimsListForTenant{
  getActiveClaimsListForTenant{
    id
    unitName
    locationAddress
    status
  }
  getHistoricalClaimsListForTenant{
    id
    unitName
    locationAddress
    status
  }
}
`;

const getClaimCreationState = gql`
query getClaimCreationState($claimId: ID!){
  getClaimCreationState(claimId: $claimId){
    creationStep
  }
}
`;

const getGeneralUnits = gql`
query getGeneralUnits($locationId: ID, $relationshipId: ID, $occupied: Boolean, $policyType: String){
  getGeneralUnits(locationId: $locationId, relationshipId: $relationshipId, occupied: $occupied, policyType: $policyType){
    id
    relationshipId
    createdAt
    updatedAt
    locationId
    unitId
    serviceId
    unitName
    unitType
    unitSize
    fmsUnitStatus
    safeleaseUnitStatus
    insurable
    tenantName
    tenantEmail
    lastIngestedAt
    leaseId
    moveInDate
    moveOutDate
    leasePaidThroughDate
    currentMonthlyRent
    policyType
    policySource
    premium
    coverage
    policyStartDate
    policyCancelDate
    privatePolicyNumber
    privatePolicyExpiration
  }
}
`;

const getGeneralUnitsPrivatePolicyData = gql`
query getGeneralUnitsPrivatePolicyData($relationshipId: ID) {
  getGeneralUnitsPrivatePolicyData(relationshipId: $relationshipId) {
    rows
    totals
  }
}
`

const getGeneralUnitHistory = gql`
  query getGeneralUnitsHistoryItems($generalUnitId: ID!){
    getGeneralUnitsHistoryItems(generalUnitId: $generalUnitId){
      id
      generalUnitId
      relationshipId
      createdAt
      updatedAt
      locationId
      unitId
      serviceId
      unitName
      unitType
      unitSize
      fmsUnitStatus
      safeleaseUnitStatus
      insurable
      tenantName
      tenantEmail
      lastIngestedAt
      leaseId
      moveInDate
      moveOutDate
      leasePaidThroughDate
      currentMonthlyRent
      policyType
      policySource
      premium
      coverage
      policyStartDate
      policyCancelDate
      privatePolicyNumber
      privatePolicyExpiration
      snapshotDate
    }
  }`;

/* Get all private policies for all locations across all locations */
const getPrivatePolicies = gql`
  query getPrivatePolicies($limit: Int, $offset: Int) {
    getPrivatePolicies(limit: $limit, offset: $offset) {
      id
      generalUnitId
      locationId
      tenantName
      moveInDate
      policyNumber
      expiration
      attachmentId
      createdAt
      updatedAt
      unitName
      extractedCompanyName
      extractedPolicyNumber
      extractedExpiration
      extractedAt
      extractionFailedAt
      email
      archivedAt
      valid
      validationStatus
      validationReason
      policyIncludesStorageUnits
      policyExcludesStorageUnits
      doubtPolicyCompliance
      errorMessage
      gptModel
      relationshipName
      locationAddress
      overrideTenantName
      overridePolicyNumber
      overrideExpiration
      tenantNameSource
      policyNumberSource
      expirationSource
      rejectionReason
      locationAddress
    }
  }
`

/* Get all private policies for a set of locations */
const getPrivatePoliciesForLocations = gql`
  query getPrivatePoliciesForLocations($locationIds: [ID]!){
    getPrivatePoliciesForLocations(locationIds: $locationIds){
      id
      locationId
      tenantName
      moveInDate
      policyNumber
      expiration
      attachmentId
      createdAt
      updatedAt
      unitName
      extractedCompanyName
      extractedPolicyNumber
      extractedExpiration
      extractedAt
      extractionFailedAt
      email
      archivedAt
      valid
      validationStatus
      validationReason
    }
  }
`

/* get expiring link to download a S3 object */
const getAttachmentEphemeralLink = gql`
  query getAttachmentEphemeralLink($attachmentId: ID!) {
    getAttachmentEphemeralLink(attachmentId: $attachmentId) {
      url
    }
  }
`

/* Return the general units and total number of safelease/nonsafelease general units for a set of locations */
const getPolicyTypeCountForLocationIds = gql`
  query getPolicyTypeCountForLocationIds($locationIds: [ID]!) {
    getPolicyTypeCountForLocationIds(locationIds: $locationIds) {
      rows
      totals
    }
  }
`

const getPrivatePolicyConfigurations = gql`
  query getPrivatePolicyConfigurations($relationshipId: ID!){
    getPrivatePolicyConfigurations(relationshipId: $relationshipId){
      locationId
      locationAddress
      fmsValidPolicyNumber
      fmsExpirationDate      
      fmsEnforcementDate
      intPoliciesOnly
      intCompliantDeclaration 
      intEnforcementDate
    }
  }`;

const getBlockedUnits = gql`
  query getBlockedUnits($locationId: ID!){
    getBlockedUnits(locationId: $locationId){
      id
      unit
      locationId
    }
  }`;

const getUpgradeProtectionPlanConfigs = gql`
  query getUpgradeProtectionPlanConfigs {
    getUpgradeProtectionPlanConfigs {
      id
      location {
        id
        address
      }
      relationship {
        id
        name
      }
      job {
        id
        status
        error
        runAt
        startedAt
      }
      upgradeAt
      deletedAt
      createdAt
      updatedAt
      status
      steps {
        id
        upgradeProtectionPlanConfigId
        oldPremium
        oldCoverage
        newPremium
        newCoverage
        deletedAt
        createdAt
        updatedAt
        completed
      }
    }
  }`;

const getCubbyOrganizations = gql`
  query getCubbyOrganizations {
    getCubbyOrganizations {
      id
      name
    }
  }
`;


export {
  getJobsAdmin,
  getRelationship,
  getRelationships,
  getRelationshipsSelector,
  getUsers,
  getUserById,
  getUserByToken,
  me,
  getLocations,
  getLocationsByLlc,
  getLocation,
  getLocationAddresses,
  getAccount,
  getAccounts,
  getAllLocations,
  getJobs,
  getLlcs,
  getSavedPaymentMethods,
  getAllLlcs,
  getAggregateMetrics,
  getEmails,
  getClaims,
  getClaimNotes,
  getUnits,
  getReportDetails,
  getReport,
  getReportDetailsTotals,
  getSummaryReport,
  getNotificationSettings,
  getPossibleClaimMatches,
  getPendingInvitations,
  getAttachRateReports,
  getPermissions,
  getUsersAdmin,
  getAllNotifications,
  listLocations,
  getAllEmails,
  getDocuments,
  getJob,
  getInternalNotes,
  getChanges,
  getFundingSources,
  getBillingEntities,
  getInvoices,
  getInvoiceLineItems,
  getRecurringInvoiceLineItems,
  getJobsErrorReport,
  getLiveProductionJobData,
  getLiveProductionLatencyByFMS,
  getClaimsMapMetrics,
  getRelationshipsByClaims,
  getLlcsByClaims,
  getAllProspects,
  getPermission,
  getTenantsLeases,
  getClaimsOverviewLists,
  getClaimCreationState,
  getGeneralUnits,
  getGeneralUnitHistory,
  getPrivatePolicies,
  getPrivatePoliciesForLocations,
  getPolicyTypeCountForLocationIds,
  getAttachmentEphemeralLink,
  getPrivatePolicyConfigurations,
  getBlockedUnits,
  getGeneralUnitsPrivatePolicyData,
  getUpgradeProtectionPlanConfigs,
  getCubbyOrganizations,
};

