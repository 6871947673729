import { Avatar, Button, ButtonProps, Divider, Typography, Box, Snackbar, Alert, IconButton, SelectChangeEvent } from "@mui/material";
import { FlexRowBox, FlexColumnBox, RowTitleText } from "./MyAccountStyledComponents";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import { styled } from "@mui/system";
import { useAuth } from "../../../auth";
import { UpdateUserDto, userApi } from "../../../utilities/apiInstances/user-api";
import { useMyAccount } from "./useMyAccountStore";
import { AlertSeverityEnum } from "./useMyAccountStore";
import NameAndTitleSection from "./sections/NameAndTitleSection";
import ContactDetailsSection from "./sections/ContactDetailsSection";
import NotificationsSection from "./sections/NotificationsSection";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../shared/Loader";
import Error from "../../../shared/Error";
import AdminSelectors from "./sections/AdminSelectors";

const StyledDivider = styled(Divider)(({ theme }) => ({
  border: "1px solid #E9E9E9",
}));

const StyledAuthUserName = styled(Typography)(({ theme }) => ({
  fontWeight: "700",
  fontSize: "18px",
  lineHeight: "24px",
}));

const StyledAuthUserRole = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "19px",
  fontColor: "#929292",
}));

const StyledUploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "black",
  backgroundColor: "white",
  textTransform: "none",
  height: "28px",
  borderRadius: "2px",
  border: "1px solid #031E30",
  fontSize: "12px",
  fontWeight: "700",
  padding: "6px, 8px, 6px, 8px",
  marginTop: "5px",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "white",
    color: "black",
    boxShadow: "none",
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  fontSize: "40px",
}));

const StyledSectionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: "auto",
  minHeight: "150px",
}));

const StyledUpdateButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
}));

const upperCaseFirstLetter = (str: string) => {
  if (!str) return null;
  return str.charAt(0).toUpperCase() + str.substring(1);
};

const MyAccount = () => {
  const auth = useAuth();
  const isAdmin = auth.user.isAdmin;
  const {
    textFieldState,
    relationshipSelectorId,
    userSelectorId,
    alertMessage,
    selectedFile,
    profileImageUrl,
    fetchMeResult,
    fetchRelationshipsSelectorResult,
    fetchUserByIdResult,
    fetchUsersForRelationshipResult,
    fetchUserProfileImageResult,
    userUploadMutation,
    setTextFieldState,
    setRelationshipSelectorId,
    setUserSelectorId,
    setAlertMessage,
    setSelectedFile,
  } = useMyAccount();

  if (
    fetchMeResult.isFetching ||
    fetchRelationshipsSelectorResult.isFetching ||
    fetchUserByIdResult.isFetching ||
    fetchUsersForRelationshipResult.isFetching ||
    fetchUserProfileImageResult.isFetching ||
    userUploadMutation.isLoading
  ) {
    return <Loader />;
  }

  if (
    fetchMeResult.isError ||
    fetchRelationshipsSelectorResult.isError ||
    fetchUserByIdResult.isError ||
    fetchUsersForRelationshipResult.isError ||
    fetchUserProfileImageResult.isError ||
    userUploadMutation.isError
  ) {
    return <Error />;
  }

  {
    /* Start: Relationship / user selector - admin use only */
  }
  const handleRelationshipSelectorChange = async (event: SelectChangeEvent) => {
    const { value: relationshipId } = event.target;
    setRelationshipSelectorId(relationshipId);
  };

  const handleUserSelectorChange = async (event: SelectChangeEvent) => {
    const { value: userId } = event.target;
    setUserSelectorId(userId);
  };
  {
    /* End: Relationship / user selector - admin use only */
  }

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("image", file);
      formData.append("userId", userSelectorId);
      userUploadMutation.mutate(formData);
    }
  };

  const handleTextFieldChange = (event) => {
    const { id, value } = event.currentTarget;
    setTextFieldState({
      [id]: value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent, targetProperty: string) => {
    const { value } = event.target;
    setTextFieldState({
      [targetProperty]: value,
    });
  };

  const updateUserInfo = async () => {
    const dto: UpdateUserDto = {
      id: +textFieldState.id,
      name: textFieldState.fullName,
      jobTitle: textFieldState.jobTitle,
      jobPosition: textFieldState.jobPosition,
      email: textFieldState.email,
      phone: textFieldState.phone,
      role: textFieldState.role,
    };

    const updatedUserResponse = await userApi.updateUser(dto);

    if (!updatedUserResponse || updatedUserResponse.errors) {
      setAlertMessage({
        message: "Error updating user settings.",
        severity: AlertSeverityEnum.ERROR,
      });
    } else {
      // Below is used to update the user in local storage. Without this, the user will not see the updated settings although the user has been updated in the database.
      // Only update the user in local storage if the user being updated is the currently logged in user. Otherwise, if an admin/superadmin is editing another user, you don't want to update local storage with that user's settings.
      // Reference: frontend/src/auth.js
      if (updatedUserResponse.id === auth.user.id) {
        const localStorageUser = JSON.parse(localStorage.getItem("user"));
        const updatedLocalStorageUser = {
          ...updatedUserResponse,
          locationIds: localStorageUser.locationIds,
        };
        localStorage.setItem("user", JSON.stringify(updatedLocalStorageUser));
      }

      // update the UI with the updated user settings
      if (isAdmin) {
        fetchUserByIdResult.refetch();
      } else {
        fetchMeResult.refetch();
      }

      setAlertMessage({
        message: "User settings updated successfully.",
        severity: AlertSeverityEnum.SUCCESS,
      });
    }
  };

  return (
    <>
      {!!alertMessage && (
        <Snackbar open anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert
            className="tw-mt-4 tw-mb-4"
            severity={alertMessage?.severity}
            action={
              <IconButton
                color="inherit"
                onClick={() => {
                  setAlertMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      )}
      {/* Relationship selector / user selector - admin use only */}
      {auth.user && isAdmin && fetchRelationshipsSelectorResult.data && (
        <AdminSelectors
          relationshipSelectorId={relationshipSelectorId}
          userSelectorId={userSelectorId}
          fetchRelationshipsSelectorResult={fetchRelationshipsSelectorResult}
          fetchUsersForRelationshipResult={fetchUsersForRelationshipResult}
          handleRelationshipSelectorChange={handleRelationshipSelectorChange}
          handleUserSelectorChange={handleUserSelectorChange}
        />
      )}
      {/* START - Upload image  */}
      <FlexRowBox>
        <FlexColumnBox>
          <StyledAvatar src={profileImageUrl || undefined}>
            <PersonIcon />
          </StyledAvatar>
          <input accept="image/*" style={{ display: "none" }} id="upload-button-file" type="file" onChange={handleFileChange} />
          <label htmlFor="upload-button-file">
            <StyledUploadButton variant="contained" component="span">
              Change image
            </StyledUploadButton>
          </label>
        </FlexColumnBox>
        <FlexColumnBox ml="-60px">
          <StyledAuthUserName>{textFieldState.fullName}</StyledAuthUserName>
          <StyledAuthUserRole>{upperCaseFirstLetter(textFieldState.role) || "N/A"}</StyledAuthUserRole>
        </FlexColumnBox>
      </FlexRowBox>
      <FlexRowBox>{selectedFile && <RowTitleText>Selected file: {selectedFile.name}</RowTitleText>}</FlexRowBox>
      {/* END - Upload image  */}

      {/* Name and job title section */}
      <StyledSectionWrapper>
        <NameAndTitleSection
          fullName={textFieldState.fullName}
          jobTitle={textFieldState.jobTitle}
          jobPosition={textFieldState.jobPosition}
          handleTextFieldChange={handleTextFieldChange}
          handleSelectChange={handleSelectChange}
        />
      </StyledSectionWrapper>

      <StyledDivider />

      {/* Contact details section */}
      <StyledSectionWrapper>
        <ContactDetailsSection email={textFieldState.email} phone={textFieldState.phone} handleTextFieldChange={handleTextFieldChange} />
      </StyledSectionWrapper>

      <StyledDivider />

      {/* Notifications section */}
      <StyledSectionWrapper>
        <NotificationsSection />
      </StyledSectionWrapper>

      <StyledUpdateButton variant="contained" color="primary" onClick={() => updateUserInfo()}>
        Update account
      </StyledUpdateButton>
    </>
  );
};

export default MyAccount;
